import { AutocompleteComponents, AutocompletePlugin, getAlgoliaResults } from "@algolia/autocomplete-js";
import type { Hit } from "@algolia/client-search";
import { SearchResponse } from "@algolia/client-search";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "next-intl";

import Icon from "~/components/common/icon";
import ProductPriceAlgolia from "~/components/products/product-price-algolia";
import ProductRatings from "~/components/products/product-ratings";
import imageUtils from "~/utils/image-utils";

import stylesButton from "../../common/button.module.scss";
import { searchClient } from "../search-client";
import styles from "./../search-dialog.module.scss";

type imageAlgolia = {
  blob: string;
  name: string;
  type: string;
  id: string;
  url: string;
};

type priceAlgolia = {
  formattedDiscountPerc?: string;
  oldValue?: number;
  value: number;
};

type ProductRecord = {
  objectID: string;
  name: string;
  availability: string[];
  baseProduct: number;
  code: string;
  multicolor: string;
  color: string;
  description: string;
  averageRating: number;
  categories: { [key: string]: string };
  category: string;
  collection: boolean;
  coverage: string;
  finishEffect: string;
  stockStatus: boolean;
  images: imageAlgolia[] | imageAlgolia;
  baseBackendId: string;
  slug: string;
  reviewsNumber: number;
  siblings: number[];
  prices: priceAlgolia;
};

export type ProductHitType = Hit<ProductRecord>;

type ProductItemProps = {
  hit: ProductHitType;
  locale: string;
  components?: AutocompleteComponents;
};

export function ProductHit({ hit, locale, components }: ProductItemProps) {
  const t = useTranslations();

  return (
    <Link
      href={`/${locale}/p/${hit.slug}-${hit.objectID}`}
      className={styles.searchProduct}
      onClick={() => {
        if ("__autocomplete_queryID" in hit) {
          // save it in localstorage for cross-tabs navigation and set it as default on session
          localStorage.setItem("algolia_queryid", hit.__autocomplete_queryID as string);
          sessionStorage.setItem("algolia_queryid", hit.__autocomplete_queryID as string);
        }
      }}
    >
      <Image
        src={
          (Array.isArray(hit.images)
            ? hit.images?.find((img: { name: string }) => img.name == "primary")?.url
            : hit.images?.url) || imageUtils.transparentPixel
        }
        alt={hit.name}
        width={80}
        height={80}
        className={styles.searchImg}
      />

      <div className={styles.searchProductCol}>
        {/* <ProductListingTags product={product} /> */}
        <h3>
          <span className={styles.clamp}>
            {components ? <components.Highlight attribute="name" hit={hit} /> : hit.name}
          </span>
        </h3>
        <div className={styles.searchProductRow}>
          <ProductPriceAlgolia price={hit.prices} />
          <div className={styles.ratings}>
            <ProductRatings average={hit.averageRating || 0} numberOfRatings={hit.reviewsNumber} />
          </div>
        </div>
        {hit.siblings?.length > 0 && (
          <div className={styles.searchShadesAvailables}>
            <span>{t("generic.shadesavailables", { 0: hit.siblings.length })}</span>
            <Icon name="chevron-right" width={16} height={16} />
          </div>
        )}
      </div>
    </Link>
  );
}

export const productsPlugin = (
  algoliaIndexId: string,
  width: number,
  viewAll: string,
  locale: string,
  newRecent: (newItem: string) => void
) => {
  return {
    getSources({ query }) {
      if (!query) {
        return [];
      }

      return [
        {
          sourceId: "productsPlugin",
          getItems({ setContext }) {
            return getAlgoliaResults<ProductHitType>({
              searchClient,
              queries: [
                {
                  indexName: algoliaIndexId,
                  query,
                  params: {
                    hitsPerPage: width >= 1280 ? 8 : width >= 900 ? 6 : 4,
                  },
                },
              ],
              transformResponse({ hits, results }) {
                setContext({
                  nbProducts: (results[0] as SearchResponse<ProductHitType>).nbHits,
                });

                return hits;
              },
            });
          },
          onSelect({ setIsOpen }) {
            setIsOpen(true);
          },
          templates: {
            item({ item, components }) {
              return <ProductHit hit={item} components={components} locale={locale} />;
            },
            footer({ state }) {
              return (
                (state.context.nbProducts as number) > 4 && (
                  <Link
                    href={`/${locale}/search?${algoliaIndexId}${encodeURIComponent("[query]")}=${encodeURIComponent(
                      state.query
                    )}`}
                    onClick={() => newRecent(state.query)}
                    className={classNames(stylesButton.button, stylesButton.primary, styles.searchButton)}
                  >
                    {viewAll}
                  </Link>
                )
              );
            },
          },
        },
      ];
    },
  } as AutocompletePlugin<ProductHitType, {}>;
};
