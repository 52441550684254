import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { mobileMenuOpenedState } from "~/atoms/mobile-menu";
import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Icon from "../common/icon";
import MediaAsset from "../common/media-asset";
import styles from "./navigation-services.module.scss";

type Props = {
  services: Entry<Editorials.NavigationItemStandardServices>;
};

export default function NavigationServices(props: Props) {
  const breakpoint = useBreakpoint();
  const router = useRouter();
  const setMobileMenuOpenedState = useSetRecoilState<boolean>(mobileMenuOpenedState);

  return (
    <div className={styles.servicesWraper}>
      {props.services.fields.list?.filter(isActiveEntry).map((el, i) => {
        if (!isActiveEntry(el.fields.link)) return null;
        const inspectorMode = contentfulUtils.useInspectorMode(el);
        const inspectorModeLink = contentfulUtils.useInspectorMode(el.fields.link);

        return (
          <Link
            key={i}
            {...inspectorModeLink?.getProps("text")}
            href={textUtils.sanitizeContentfulUrl(el.fields.link, router)}
            target={el.fields.link.fields.openOnANewTab ? "_blank" : undefined}
            className={styles.serviceItem}
            onClick={() => setMobileMenuOpenedState(false)}
          >
            {el.fields.icon && <MediaAsset entry={el.fields.icon} width={32} height={32} />}
            <div className={styles.serviceText}>
              <p>{el.fields.link.fields.text}</p>
              {el.fields.paragraph && breakpoint == "desktop" && (
                <span {...inspectorMode?.getProps("paragraph")}>{el.fields.paragraph}</span>
              )}
            </div>
            <div className={styles.iconWrapper}>
              <Icon name="arrow-right" />
            </div>
          </Link>
        );
      })}
    </div>
  );
}
