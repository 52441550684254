import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { motion } from "framer-motion";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import { countryDialogOpenedState } from "~/atoms/country-dialog";
import { mobileMenuOpenedState } from "~/atoms/mobile-menu";
import CrossCountry from "~/types/cross-country";
import crossCountryUtils from "~/utils/cross-country-utils";
import { CultureLink } from "~/utils/locale-utils";

import countries from "../../../public/json/countries.json";
import styles from "./country-dialog.module.scss";
import Dialog from "./dialog";
import Icon from "./icon";

type Props = {
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
};

const CountryDialogTile = forwardRef<HTMLDivElement, { country: CrossCountry.CountryType; active: boolean }>(
  function CountryDialogTile({ country, active }, ref) {
    const defaultCulture = country.cultures.find((c) => c.default) ?? country.cultures[0];

    return (
      <div ref={ref} className={classNames(styles.countryListItem, active && styles.countryListItemActive)}>
        <CultureLink country={country} culture={defaultCulture} className={styles.countryLink}>
          <div className={styles.countryFlag}>
            <Image
              unoptimized
              width={16}
              height={16}
              aria-hidden="true"
              alt={country.englishName}
              src={`/next/assets/flags/${country.countryCode}.svg`}
            />
          </div>
          <div className={styles.countryName}>
            <span>{country.name}</span>
          </div>
        </CultureLink>
        {!country.isShoppable ? (
          <div className={styles.countryHasEcommerce}>
            <Icon name="non-shoppable-country" aria-hidden />
          </div>
        ) : null}
        {country.cultures.length > 1 && (
          <div className={styles.countryCultureListWrapper}>
            {/* Culture list */}
            {country.cultures.map((culture) => (
              <CultureLink
                key={culture.locale}
                country={country}
                culture={culture}
                className={styles.countryCultureLink}
              >
                <span>
                  {"displayName" in culture ? culture.displayName : culture.locale.split("-")[0].toUpperCase()}
                </span>
              </CultureLink>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default function CountryDialog({ onAfterOpen, onAfterClose }: Props) {
  const t = useTranslations();
  const router = useRouter();
  const [tabIsClicked, setTabIsClicked] = useState(false);
  const [countryDialogOpened, setCountryDialogOpened] = useRecoilState<boolean>(countryDialogOpenedState);
  const setMobileMenuOpenedState = useSetRecoilState<boolean>(mobileMenuOpenedState);
  const refContinentTabs = useRef<HTMLElement[] | null[] | undefined[]>([]);
  const refCountryLinks = useRef<HTMLElement[] | null[] | undefined[]>([]);
  const initialCountry = crossCountryUtils.getCurrentCountryCode(router).toLowerCase();

  const initialContinentIndex = useMemo(() => {
    return countries.continents.findIndex((c) => c.countries.some((cc) => cc.countryCode === initialCountry));
  }, [initialCountry]);

  const initialCountryIndex = useMemo(() => {
    return countries.continents[initialContinentIndex].countries.findIndex((cc) => cc.countryCode === initialCountry);
  }, [initialCountry, initialContinentIndex]);

  const [currentContinentIndex, setCurrentContinentIndex] = useState<number>(initialContinentIndex);

  const onClose = () => {
    setCountryDialogOpened(false);
  };

  useEffect(() => {
    // wait for the virtual DOM to udpate before scrolling
    const scrollTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      refContinentTabs.current[currentContinentIndex]?.scrollIntoView({ behavior: "smooth", inline: "center" });
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [refContinentTabs, currentContinentIndex]);

  useEffect(() => {
    let scrollTimeout: ReturnType<typeof setTimeout> | undefined = undefined;
    if (initialCountryIndex >= 0 && initialContinentIndex === currentContinentIndex) {
      // wait for the virtual DOM to udpate before scrolling
      scrollTimeout = setTimeout(() => {
        refCountryLinks.current[initialCountryIndex]?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    return () => scrollTimeout && clearTimeout(scrollTimeout);
  }, [refCountryLinks, initialContinentIndex, initialCountryIndex, currentContinentIndex]);

  return (
    <Dialog
      isOpen={countryDialogOpened}
      title={
        <>
          <Icon name="country" />
          <span id="changecountry-dialog-headline">{t("generic.select_your_country")}</span>
        </>
      }
      onClose={onClose}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      customStyles={styles}
    >
      <Tab.Group selectedIndex={currentContinentIndex} onChange={setCurrentContinentIndex}>
        {/* Continent tabs */}
        <Tab.List
          className={styles.countryTabWrapper}
          aria-roledescription="tablist"
          aria-labelledby={`changecountry-dialog-headline`}
        >
          {countries.continents.map((continent, continentIndex) => (
            <Tab
              onClick={() => setTabIsClicked(true)}
              onKeyUp={(e) => (e.key === "Tab" ? setTabIsClicked(false) : undefined)}
              ref={(ref) => {
                refContinentTabs.current[continentIndex] = ref;
              }}
              key={`countryDialog-tablist-${continentIndex}`}
              className={classNames(styles.countryTabContainer, tabIsClicked ? styles.clickedTab : undefined)}
              aria-roledescription="tab"
              aria-controls={`countryDialog-tabpanel-${continentIndex}`}
            >
              {({ selected }) => (
                <>
                  <div className={styles.countryTabButton}>{continent.name}</div>
                  {selected && (
                    <motion.div className={styles.countryTabButtonActive} layoutId="selected-CountryDialog" />
                  )}
                </>
              )}
            </Tab>
          ))}
        </Tab.List>
        {/* Continent panels */}
        <Tab.Panels>
          {countries.continents.map((continent, continentIndex) => (
            <Tab.Panel
              id={`countryDialog-tabpanel-${continentIndex}`}
              as="div"
              key={`countryDialog-tabpanel-${continentIndex}`}
              aria-labelledby={`countryDialog-tablist-${continentIndex}`}
              aria-roledescription="tabpanel"
              className={styles.countryListContainer}
            >
              {/* Country list */}
              {continent.countries.map((country, countryIndex) => (
                <CountryDialogTile
                  key={country.countryCode}
                  active={country.countryCode === initialCountry}
                  country={country}
                  ref={(ref) => {
                    // only save ref array of the current continent
                    if (continentIndex === initialContinentIndex) {
                      refCountryLinks.current[countryIndex] = ref;
                    }
                  }}
                />
              ))}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Dialog>
  );
}
