import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { AbstractIntlMessages, useTranslations } from "next-intl";
import { useEffect } from "react";

import ModalAdmin from "~/components/login/modal-admin";
import { useAuthContext } from "~/contexts/auth";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useCartNotification } from "~/contexts/cart-notification";
import { useWishlist } from "~/contexts/wishlist";
import { useCart } from "~/hooks/use-cart";
import useSubscriptions from "~/hooks/use-subscriptions";
import { LoginComponentContext } from "~/shared/user/login-form";
import Editorials from "~/types/editorials";
import appRoutes from "~/utils/app-routes";
import Constants from "~/utils/constants";
import { assertEditorialType } from "~/utils/editorial-utils";
import loyaltyUtils from "~/utils/loyalty-utils";
import useScrollDirection from "~/utils/use-scroll-direction";

import Container from "../common/container";
import Icon from "../common/icon";
import { PagePopup, PagePopupClose, PagePopupContent, PagePopupTrigger } from "../common/page-popup";
import ModalGuest from "../login/modal-guest";
import stylesPagePopupGuest from "../login/modal-guest.module.scss";
import ModalLogged from "../login/modal-logged";
import stylesPagePopupLogged from "../login/modal-logged.module.scss";
import MiniCart from "../mini-cart/mini-cart";
import styles from "./header.module.scss";
import MenuMobile from "./menu-mobile";
import Navigation from "./navigation";
import SeoNavigation from "./seo-navigation";
import TopBar from "./top-bar";

type Props = {
  entry: Entry<unknown>;
  navColor: Editorials.navColor;
  inPpd?: boolean;
  searchSuggestions?: string[];
  messages?: AbstractIntlMessages;
};

export default function Header(props: Props) {
  const t = useTranslations();
  const router = useRouter();
  const { isHovering, setHovering } = useCartNotification();
  const { products } = useWishlist();

  const cart = useCart();
  const shouldShowCart = !!cart && !cart.ShoppingCart.IsPaid;

  const isHomepage = router.asPath === "/";

  const breakpoint = useBreakpoint();
  const otherScrollDetector = useScrollDirection({});
  const { dir, y } = useScrollDirection({
    initialDirection: "up",
    thresholdPixels: breakpoint == "desktop" ? Constants.HEADER_HEIGHT_DESKTOP : Constants.HEADER_HEIGHT_MOBILE,
  });

  const { isAuthenticated, user } = useAuthContext();
  const { userSubscriptions } = useSubscriptions();

  const isUserSubscribedToLoyalty = loyaltyUtils.getLoyaltySubscription(userSubscriptions)?.Status == 2 ?? false;

  useEffect(() => {
    if (y >= Constants.HEADER_HEIGHT_DESKTOP) {
      document.body.style.setProperty("--top-menu", "80px");
      document.body.style.setProperty("--header-menu", "0");
    } else {
      document.body.style.setProperty("--top-menu", `${Constants.HEADER_HEIGHT_DESKTOP}px`);
      document.body.style.setProperty("--header-menu", "80px");
    }
  }, [y]);

  function onOpenMinicartChange(open: boolean) {
    if (open) {
      setHovering(true);
    } else {
      setTimeout(() => setHovering(false), 500);
    }
  }

  const cartLink = (
    <Link className={styles.headerElement} href={appRoutes.CART} tabIndex={-1} aria-hidden={true}>
      <Icon name="cart" value={shouldShowCart ? cart.ShoppingCart.TotalItems || undefined : undefined} />
    </Link>
  );

  assertEditorialType<Editorials.Nav>(props.entry, ["navigation"]);

  return (
    <header
      id="header"
      className={classNames(
        styles.header,
        { [styles.scrolledTotal]: dir == "down" },
        { [styles.scrolledTop]: y >= Constants.HEADER_HEIGHT_DESKTOP },
        { [styles.scrolledNavBg]: otherScrollDetector.y >= 1 },
        { [styles.inPdp]: props.inPpd },
        styles[props.navColor]
      )}
    >
      <TopBar entry={props.entry.fields.topBar} />
      <div className={styles.main}>
        <Container className={styles.mainContainer}>
          {/* which contains search */}
          <MenuMobile nav={props.entry} messages={props.messages} searchSuggestions={props.searchSuggestions} />
          <div className={styles.logoWrapper}>
            <Link href="/" className={styles.center} prefetch={false}>
              <Icon name="kiko" aria-hidden className={styles.logoIcon} />
              {isHomepage && (
                <h1>
                  <span className="sr-only">KIKO</span>
                </h1>
              )}
            </Link>
          </div>

          {isAuthenticated ? (
            <div className={styles.helloContainer}>
              {t("generic.hello_user", { name: user?.FirstName }).toUpperCase()}
            </div>
          ) : null}

          {/* User popover (only on desktop) */}
          {breakpoint === "desktop" ? (
            isAuthenticated ? (
              <>
                {isUserSubscribedToLoyalty ? (
                  <PagePopup closesOn="click" opensOnSR={true}>
                    {/* Registered user PagePopup */}
                    <PagePopupTrigger aria-label="generic.account.toggle">
                      <Link
                        href={appRoutes.MYACCOUNT}
                        className={styles.headerElement}
                        tabIndex={-1}
                        aria-hidden={true}
                      >
                        <Icon name="account" value={user?.FullName[0].toUpperCase()} />
                      </Link>
                    </PagePopupTrigger>
                    <PagePopupContent>
                      <PagePopupClose className={stylesPagePopupLogged.closeButton}>
                        <Icon name="close" width={16} height={16} />
                      </PagePopupClose>
                      <ModalLogged />
                      <ModalAdmin></ModalAdmin>
                    </PagePopupContent>
                  </PagePopup>
                ) : (
                  <Link href={appRoutes.MYACCOUNT} className={styles.headerElement}>
                    <Icon name="account" value={user?.FullName[0].toUpperCase()} />
                  </Link>
                )}
              </>
            ) : (
              <PagePopup closesOn="click" opensOnSR={true}>
                {/* Guest user PagePopup */}
                <PagePopupTrigger aria-label="generic.account.toggle">
                  <Link href={appRoutes.LOGIN} className={styles.headerElement} tabIndex={-1} aria-hidden={true}>
                    <Icon name="account" />
                  </Link>
                </PagePopupTrigger>
                <PagePopupContent>
                  <PagePopupClose className={stylesPagePopupGuest.closeButton}>
                    <Icon name="close" width={16} height={16} />
                  </PagePopupClose>
                  <ModalGuest context={LoginComponentContext.headerModal} />
                </PagePopupContent>
              </PagePopup>
            )
          ) : (
            <Link href={`${isAuthenticated ? appRoutes.MYACCOUNT : appRoutes.LOGIN}`} className={styles.headerElement}>
              <Icon name="account" value={isAuthenticated ? user?.FullName[0].toUpperCase() ?? undefined : undefined} />
            </Link>
          )}
          {/* {breakpoint == "desktop" && ( */}
          <Link
            href={isAuthenticated ? appRoutes.MYACCOUNT_WISHLIST : appRoutes.LOGIN}
            className={styles.headerElement}
            data-desktop-only
          >
            <Icon name="heart" value={products?.length || undefined} />
          </Link>
          {/* )} */}
          <PagePopup open={isHovering} onOpenChange={onOpenMinicartChange} opensOnSR={true}>
            {breakpoint == "desktop" ? (
              <PagePopupTrigger aria-label="generic.cart.toggle">{cartLink}</PagePopupTrigger>
            ) : (
              cartLink
            )}
            <MiniCart />
          </PagePopup>
        </Container>
      </div>
      <Navigation nav={props.entry} className={styles.headerNav} />
      <SeoNavigation nav={props.entry} />
    </header>
  );
}
