import { zodResolver } from "@hookform/resolvers/zod";
import {
  KikCustomerLoginEvaFieldsSchema,
  KikCustomerLoginFields,
  KikSocialSignInKeys as SocialSignInKeys,
  KikUserZodSchemas as ZodSchemas,
} from "@kikocosmeticsorg/uc-api-nest-common-fe";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import SocialSignInButton from "~/components/common/social-sign-in-buttons/social-sign-in-button";
import { useAuthContext } from "~/contexts/auth";
import { useBreakpoint } from "~/contexts/breakpoint";
import { useServerSideProps } from "~/contexts/server-side-props";
import Logger from "~/services/logger/logger";
import { LoginComponentContext } from "~/shared/user/login-form";
import { LoginSignUpOrigins } from "~/shared/user/login-sign-up-origins.enum";
import Editorials from "~/types/editorials";
import appRoutes from "~/utils/app-routes";
import { assertEditorialType } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import Button from "../common/button";
import LinkWrapper from "../common/link-wrapper";
import Checkbox, { CheckboxVariants } from "../form/checkbox";
import Errors from "../form/errors";
import InputField from "../form/input-field";
import inputFieldStyles from "../form/input-field.module.scss";
import styles from "./login.module.scss";

type Props = {
  context?: LoginComponentContext;
};

export default function Login({ context = LoginComponentContext.default }: Props) {
  const t = useTranslations();
  const breakpoint = useBreakpoint();
  const { authError, login, logout, isLoading, signInWithSocialProvider, enabledSocials } = useAuthContext();
  const router = useRouter();
  const { serverSideProps } = useServerSideProps();

  const contentUtils = serverSideProps.contentUtils;
  assertEditorialType<Editorials.ContentUtils>(contentUtils, "contentUtils");

  const privacyPolicyLink = textUtils.sanitizeContentfulUrl(contentUtils.fields.privacyPolicyLink, router);

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<KikCustomerLoginFields>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldFocusError: false,
    resolver: zodResolver(KikCustomerLoginEvaFieldsSchema),
  });

  useEffect(() => {
    if (authError) {
      setError("root.serverError", {
        type: `${authError.errorCode}`,
      });
    }
  }, [authError, setError]);

  const onSubmit: SubmitHandler<KikCustomerLoginFields> = async (loginFields: KikCustomerLoginFields) => {
    await logout();
    return await login(loginFields);
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const getLoginModalAdditionalClassNames = useCallback(() => {
    switch (context) {
      case LoginComponentContext.headerModal:
        return styles.modalLoginContainer;
      case LoginComponentContext.cartModal:
        return styles.modalLoginContainer;
      default:
      case LoginComponentContext.default:
        return undefined;
    }
  }, [context]);

  const renderLoginHeadline = useCallback(() => {
    switch (context) {
      case LoginComponentContext.headerModal:
        return <h2 className={styles.modalLoginHeadline}>{t("generic.welcome_back")}</h2>;
      case LoginComponentContext.cartModal:
        return <h2 className={styles.modalLoginHeadline}>{t("generic.cartLoginDialogHeadline")}</h2>;
      default:
      case LoginComponentContext.default:
        return <h2 className={styles.loginHeadline}>{t("generic.login")}</h2>;
    }
  }, [context, t]);

  const renderLoginParagraph = useCallback(() => {
    switch (context) {
      case LoginComponentContext.headerModal:
        return <p className={styles.modalLoginParagraph}>{t("generic.already_have_account")}</p>;
      case LoginComponentContext.cartModal:
        return <p className={styles.modalLoginParagraph}>{t("generic.already_have_account")}</p>;
      default:
      case LoginComponentContext.default:
        return null;
    }
  }, [context, t]);

  const onSocialSignInClick = async (socialKey: SocialSignInKeys) => {
    Logger.instance.debug("SocialSignInButton: clicked", socialKey);
    // await logout();
    await signInWithSocialProvider(socialKey);
  };

  const getSocialSignInIsModalValue = useMemo(() => {
    switch (context) {
      case LoginComponentContext.headerModal:
        return true;
      case LoginComponentContext.cartModal:
        return true;
      default:
      case LoginComponentContext.default:
        return false;
    }
  }, [context]);

  const goToSignUp = () => {
    const signUpRedirect = new URL(["", router.locale, appRoutes.SIGN_UP].join("/"), location.origin);
    router.query.origin && signUpRedirect.searchParams.set("origin", router.query.origin as string);
    router.push(signUpRedirect.href);
  };

  return (
    <div className={classNames(styles.loginContainer, getLoginModalAdditionalClassNames())}>
      {renderLoginHeadline()}
      {renderLoginParagraph()}
      {breakpoint === "mobile" && enabledSocials.length > 0 ? (
        <div className={classNames(styles.wrapperSocialLogin, isLoading ? "loading" : "")}>
          {enabledSocials.map((socialKey: SocialSignInKeys, index) => {
            return (
              <SocialSignInButton
                disabled={isLoading}
                onClick={onSocialSignInClick}
                socialKey={socialKey}
                key={`${socialKey}-socialLogin-${index}`}
                hideLabels={getSocialSignInIsModalValue}
              ></SocialSignInButton>
            );
          })}
        </div>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register("EmailAddress")}
          type="email"
          label={t("generic.form.EmailAddress")}
          error={
            errors.EmailAddress?.message
              ? t(errors.EmailAddress?.message, {
                  fieldName: t("generic.form.EmailAddress"),
                  maxLength: ZodSchemas.emailMaxlength,
                })
              : ""
          }
          onClearErrors={() => clearErrors("EmailAddress")}
          className={styles.inputFieldContainer}
        />
        <InputField
          {...register("password")}
          type={showPassword ? "text" : "password"}
          label={t("generic.form.password_label")}
          error={
            errors.password?.message
              ? t(errors.password?.message, {
                  fieldName: t("generic.form.password_label"),
                  minLength: ZodSchemas.passwordMinlength,
                })
              : ""
          }
          buttonName={
            <Checkbox
              label={t("generic.form.show_hide_password")}
              variant={CheckboxVariants.visibility}
              width={16}
              height={16}
              customStyles={{
                icon: inputFieldStyles.iconvisibility,
                container: inputFieldStyles.containerShowHidePassowrdCheckbox,
                labelCheckbox: inputFieldStyles.labelShowHidePassowrdCheckbox,
              }}
              onChange={() => setShowPassword(!showPassword)}
            />
          }
          onClearErrors={() => clearErrors("password")}
          className={styles.inputFieldContainer}
        />
        <div className={styles.wrapperHandlePassword}>
          {/*<Checkbox*/}
          {/*  {...register("RememberMe")}*/}
          {/*  label={t("generic.remember_me")}*/}
          {/*  className={styles.checkboxRememberMe}*/}
          {/*/>*/}
          <Link className={styles.forgotPassword} href={`/${appRoutes.PASSWORD_RESET_REQUEST}`}>
            {t("generic.forgot_your_password")}
          </Link>
        </div>

        <Errors error={authError} />

        <p className={styles.privacyDisclaimer}>
          {t.rich("generic.loginPrivacyDisclaimer", {
            privacyPolicyLink: (chunks) => (
              <Link className={styles.privacyPolicyLink} href={privacyPolicyLink} target="_blank">
                {chunks}
              </Link>
            ),
          })}
        </p>

        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting || isLoading}
          loading={isSubmitting || isLoading}
          className={styles.loginButton}
        >
          {t("generic.login")}
        </Button>
      </form>
      {enabledSocials.length > 0 && breakpoint === "desktop" ? (
        <>
          <p className={styles.socialLoginHeadline}>{t("generic.login_with_social_account")}</p>
          <div className={classNames(styles.wrapperSocialLogin, isLoading ? "loading" : "")}>
            {enabledSocials.map((socialKey: SocialSignInKeys, index) => {
              return (
                <SocialSignInButton
                  disabled={isLoading}
                  onClick={onSocialSignInClick}
                  socialKey={socialKey}
                  key={`${socialKey}-socialLogin-${index}`}
                  hideLabels={getSocialSignInIsModalValue}
                ></SocialSignInButton>
              );
            })}
          </div>
        </>
      ) : null}

      {router.query.origin === LoginSignUpOrigins.cart && (
        <>
          {breakpoint === "mobile" ? (
            <>
              <p className={styles.paragraph}>{t("generic.or")}</p>
              <Button variant="secondary" onClick={() => goToSignUp()}>
                {t("generic.create_account")}
              </Button>
            </>
          ) : null}
          <div className={styles.guestWrapper}>
            {t.rich("generic.proceed_as_guest", {
              link: (chunks) => (
                <LinkWrapper href={appRoutes.CHECKOUT} className={styles.proceedGuestLink}>
                  {chunks}
                </LinkWrapper>
              ),
            })}
          </div>
        </>
      )}
    </div>
  );
}
