import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import { Dispatch, SetStateAction } from "react";
import { useSetRecoilState } from "recoil";

import { mobileMenuOpenedState } from "~/atoms/mobile-menu";
import EscapeNewLine from "~/components/common/escape-new-line";
import HighlightedText from "~/components/common/highlighted-text";
import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import styles from "./navigation-item-collections-variant-item.module.scss";

type Props = {
  entry: Entry<Editorials.NavigationItemStandardCollectionsItem>;
  setCurrentIndex?: Dispatch<SetStateAction<number>>;
  index?: number;
};

export default function NavigationItemCollectionsVariantItem({ entry, setCurrentIndex, index = 0 }: Props) {
  const { tag, title, link, image } = entry.fields;
  const t = useTranslations();
  const router = useRouter();
  const setMobileMenuOpenedState = useSetRecoilState<boolean>(mobileMenuOpenedState);

  if (!isActiveEntry(link)) {
    return null;
  }

  if (!link.fields.url && !link.fields.pageRef) {
    return null;
  }

  const inspectorMode = contentfulUtils.useInspectorMode(entry);
  const inspectorModeLink = contentfulUtils.useInspectorMode(link);

  return (
    <Link
      prefetch={false}
      aria-label={title}
      onMouseEnter={() => setCurrentIndex?.(index)}
      onFocus={() => setCurrentIndex?.(index)}
      href={textUtils.sanitizeContentfulUrl(link, router)}
      target={link.fields.openOnANewTab ? "_blank" : undefined}
      className={styles.collectionItem}
      onClick={() => setMobileMenuOpenedState(false)}
    >
      <div className={styles.imageWrapper} {...inspectorMode?.getProps("image")}>
        <MediaAsset entry={image} width={72} height={72} className={styles.preview} />
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.tag} {...inspectorMode?.getProps("tag")}>
          <EscapeNewLine text={tag} />
        </div>
        <div
          {...inspectorMode?.getProps("title")}
          className={contentfulUtils.isHighlightText(title) ? styles.titleHighlighted : styles.title}
        >
          <HighlightedText text={title} />
        </div>
        <div className={styles.link} {...inspectorModeLink?.getProps("url")}>
          {t("generic.discoverMore")}
        </div>
      </div>
    </Link>
  );
}
