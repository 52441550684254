import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSetRecoilState } from "recoil";

import { mobileMenuOpenedState } from "~/atoms/mobile-menu";
import EscapeNewLine from "~/components/common/escape-new-line";
import HighlightedText from "~/components/common/highlighted-text";
import Icon from "~/components/common/icon";
import MediaAsset from "~/components/common/media-asset";
import EditorialLink from "~/components/editorials/editorial-link/editorial-link";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import styles from "./navigation-item-services-variant-item.module.scss";

type Props = {
  entry: Entry<Editorials.NavigationItemStandardServicesItem>;
  isMobile?: boolean;
};

export default function NavigationItemServicesVariantItem({ entry, isMobile = false }: Props) {
  const setMobileMenuOpenedState = useSetRecoilState<boolean>(mobileMenuOpenedState);
  const router = useRouter();

  const inspectorMode = contentfulUtils.useInspectorMode(entry);
  const inspectorModeLink = contentfulUtils.useInspectorMode(entry.fields.link);

  if (isMobile) {
    return (
      <>
        {isActiveEntry(entry.fields.link) ? (
          <Link
            href={textUtils.sanitizeContentfulUrl(entry.fields.link, router)}
            target={entry.fields.link.fields.openOnANewTab ? "_blank" : undefined}
            className={styles.serviceItemContainerMobile}
            aria-label={entry.fields.link.fields.text}
            onClick={() => setMobileMenuOpenedState(false)}
            {...inspectorModeLink?.getProps("url")}
          >
            <div className={styles.serviceItemContainerDescriptionMobile}>
              {entry.fields.title ? (
                <div
                  {...inspectorMode?.getProps("title")}
                  className={
                    contentfulUtils.isHighlightText(entry.fields.title)
                      ? styles.serviceItemTitleMobileHighlighted
                      : styles.serviceItemTitleMobile
                  }
                >
                  <HighlightedText text={entry.fields.title} />
                </div>
              ) : null}
              {entry.fields.paragraph ? (
                <p className={styles.paragraph}>
                  <span {...inspectorMode?.getProps("paragraph")}>
                    <EscapeNewLine text={entry.fields.paragraph} />
                  </span>
                  <Icon name="arrow-right" width={16} height={16} className={styles.iconArrow} />
                </p>
              ) : null}
            </div>
            {entry.fields.image ? (
              <div className={styles.mediaContainer} {...inspectorMode?.getProps("image")}>
                <MediaAsset entry={entry.fields.image} width={80} height={80} />
              </div>
            ) : null}
          </Link>
        ) : null}
      </>
    );
  } else {
    return (
      <div className={styles.serviceItemContainer}>
        {entry.fields.image ? (
          <div className={styles.mediaContainer} {...inspectorMode?.getProps("image")}>
            <MediaAsset entry={entry.fields.image} sizes="20vw" />
          </div>
        ) : null}
        {entry.fields.title ? (
          <div
            {...inspectorMode?.getProps("title")}
            className={
              contentfulUtils.isHighlightText(entry.fields.title)
                ? styles.serviceItemTitleHighlighted
                : styles.serviceItemTitle
            }
          >
            <HighlightedText text={entry.fields.title} />
          </div>
        ) : null}
        <EditorialLink entry={entry.fields.link} lookAsButton="secondary" className={styles.link} />
      </div>
    );
  }
}
